
/*

     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      navigation_main.scss                                   *
     * created on:    06-11-2016 - 14:08:46 GMT+1                            *
     * last modified: 27-12-2022 - 15:12:47 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan 'Stefko' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* Mini Reset */

#header li strong {
  white-space: nowrap;
  font-weight: normal;
}

#header .mod_navigation,
#header .mod_navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#header .mod_navigation::before,
#header .mod_navigation::after {
  content: "";
  display: table;
}

#header .mod_navigation::after {
  clear: both;
}

/* =====================================================================================
     Horizontale Navigation -> http://red-team-design.com/css3-animated-dropdown-menu/
   ===================================================================================== */

/*     -- Navigation - Erste Ebene
------------------------------------------------- */

#header .mod_navigation {
  z-index: 9999;
  overflow: visible !important;
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: $hgt-navi-main;
  zoom: 1;
  @include transition-fst;
}

#header.sticky .mod_navigation {
  height: $hgt-navi-main;
}

#header::after { /* Navi Hintergrund für 100% Breite */
  content: " ";
  overflow: visible !important;
  position: absolute;
  bottom: 0;
  min-width: 100%;
  height: $hgt-navi-main;
  @include grd-nav-bg;
}

#header .mod_navigation .level_1 {
  max-width: $wdt-navi-max;
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  @include transition-fst;
}

#header .mod_navigation .level_1 > li {
  float: left;
  border-right: 1px solid $grd-drk-top;
  box-shadow: 1px 0 0 $grd-drk-btm;
  position: relative;
}

#header .mod_navigation .level_1 > li:first-child { /* Beim ersten Menüelement links eine Border anbzeigen */
  border-left: 1px solid $grd-drk-top;
}

#header.sticky .mod_navigation::after {
  display: inline-block;
  float: left;
  content: url(/files/layout/gfx/Nordlicht_Logo.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 25px;
  margin-top: -50px;
  transform: scale(.06);
  @include transition-fst;
}

#header.sticky .mod_navigation .level_1 {
  margin-left: 77px;
  @include transition-fst;
}

#header .mod_navigation .level_1 li.account a::before {
  content: ' \f007';
  font-family: "Font Awesome 5 Free", serif;
  padding-right: 5px;
}

#header .mod_navigation .level_1 > li a,
#header .mod_navigation .level_1 > li strong {
  float: left;
  //padding: $pad-nav-main-vrt+1px $pad-nav-main-hor $pad-nav-main-vrt $pad-nav-main-hor;
  padding: 10px 2rem 12px 2rem;
  color: $clr-txt-nav;
  text-decoration: none;
  @include transition-fst;
}

#header.sticky .mod_navigation .level_1 > li a,
#header.sticky .mod_navigation .level_1 > li strong {
  padding: 10px 2rem; //($pad-nav-main-vrt+1px - $sticky/2) $pad-nav-main-hor ($pad-nav-main-vrt - $sticky/2) $pad-nav-main-hor;
}

#header.sticky .mod_navigation .level_1 strong.active {
  padding-top: 10px; // $pad-nav-main-vrt+1px - $sticky/2;
  padding-bottom: 10px; //$pad-nav-main-vrt+1px - $sticky/2;
}

#header .mod_navigation .level_1 > li:hover > a,
#header .mod_navigation .level_1 > li .active,
#header .mod_navigation .level_1 > li .trail,
#header .mod_navigation .level_1 > li .forward {
  color: $clr-txt-nav-hov;
  font-weight: normal;
  //@include grd-nav-hvr;
  background: $grd-drk-btm;
  @include transition-fst;
}

/* Markierung, wenn ein weiteres Submenü vorhanden ist */
#header .mod_navigation .level_1 > li.submenu > a:link::after,
#header .mod_navigation .level_1 > li.submenu > strong::after {
  display: inline-block; // needed for rotation of inline-element
  content: "\f105";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  transform: rotate(90deg);
  @include transition-fst;
}

/* Markierung der ersten Ebene bei Hover ändern */
#header .mod_navigation .level_1 > li.submenu:hover > a:link::after,
#header .mod_navigation .level_1 > li.submenu:hover > strong::after {
  padding-left: 5px; // padding-Richtung dreht sich mit!
  padding-right: 5px;
  transform: rotate(270deg);
  @include transition-fst;
}

/*     -- Navigation - Zweite Ebene
------------------------------------------------- */

/* Zweite, Dritte & Vierte Ebene ausblenden */
#header .mod_navigation .level_2,
#header .mod_navigation .level_3,
#header .mod_navigation .level_4 {
  position: absolute;
  left: –9999rem;
  top: –9999rem;
  overflow: hidden;
  display: inline-block;
  height: 0;
  z-index: 1; /* "nach vorne", über den Inhaltsbereich */
  @include transition-fst;
}

/* Zweite Ebene bei MouseOver einblenden */
#header .mod_navigation .level_1 li:hover .level_2 {
  overflow: visible !important;
  top: ($hgt-navi-main - 1px);
  left: -1px;
  width: $wdt-navi-level2;
  height: auto;
  float: none;
  display: block;
  background: $grd-drk-btm;
  //@include grd-nav-hvr;
  @include box-shadow-nrm;
  @include transition-fst;
}

#header.sticky .mod_navigation .level_1 li:hover .level_2 {
  top: $hgt-navi-main - 3px;
}

#header .mod_navigation .level_2 li {
  float: none;
  display: inline-block;
  width: 100%;
  border-right: none;
  border-top: 1px solid rgba(51, 51, 51, .5);
  @include transition-fst;
}

#header .mod_navigation .level_2 li a,
#header .mod_navigation .level_2 li strong {
  white-space: nowrap;
  padding: ($pad-nav-main-vrt / 2) $pad-nav-main-hor ($pad-nav-main-vrt / 2) $pad-nav-main-hor;
  border-left: 1px solid $grd-drk-top;
}

#header .mod_navigation .level_2 > li:hover > a {
  color: $orange-drk;
  text-align: left;
  width: $wdt-navi-level2 - ($pad-nav-main-hor * 2) - 1;
  background: $grd-drk-btm;
  //@include grd-nav-bg;
  @include transition-fst;
}

#header .mod_navigation .level_2 > li.active,
#header .mod_navigation .level_2 > li.trail {
  color: $orange-drk;
  width: $wdt-navi-level2;// - ($pad-nav-main-hor * 2) - 1;
  @include transition-fst;
}

/* Dreieckchen über erstem Submenü Item */
//#header .mod_navigation .level_2 li:first-child a::after {
//    content: '';
//    position: absolute;
//    left: 30px;
//    top: -8px;
//    width: 0;
//    height: 0;
//    border-left: 5px solid transparent;
//    border-right: 5px solid transparent;
//    border-bottom: 8px solid $grd-drk-btm;
//}

/* Markierung, wenn ein weiteres Submenü vorhanden ist */

#header .mod_navigation .level_2 > li.submenu > a:link span::after,
#header .mod_navigation .level_2 > li.submenu > strong::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 700;
  position: absolute;
  right: 15px;
  display: inline-block;
}

/*     -- Navigation - Dritte Ebene
------------------------------------------------- */

/* Dritte Ebene bei MouseOver einblenden */
#header .mod_navigation .level_2 li:hover .level_3 {
  overflow: visible !important;
  margin-top: -1px;
  left: $wdt-navi-level2;
  width: $wdt-navi-level3;
  height: auto;
  float: none;
  display: block;
  //background:$green-drk;
  background: $grd-drk-btm;
  @include box-shadow-nrm;
  @include transition-fst;
}

#header .mod_navigation .level3 li {
  float: none;
  display: inline-block;
  width: 100%;
  border-right: none;
  border-top: 1px solid rgba(51, 51, 51, .5);
  @include transition-fst;
}

#header .mod_navigation .level_3 > li a,
#header .mod_navigation .level_3 > li strong {
  white-space: normal;
  border-left: 1px solid $grd-drk-top;
  padding: ($pad-nav-main-vrt / 2) $pad-nav-main-hor ($pad-nav-main-vrt / 2) $pad-nav-main-hor;
}

#header .mod_navigation .level_3 > li.active,
#header .mod_navigation .level_3 > li.trail {
  color: $orange-drk;
  width: $wdt-navi-level3;
  @include transition-fst;
}

#header .mod_navigation .level_3 > li:hover > a {
  text-align: left;
  width: $wdt-navi-level3  - ($pad-nav-main-hor * 2) - 1 !important;
  color: $orange-drk;
  @include grd-nav-bg;
  @include transition-fst;
}

/* Dreieckchen nebem erstem Level3 Item */
//#header .mod_navigation .level_3 li:first-child a::after {
//    left: -8px;
//    top: 12px;
//    width: 0;
//    height: 0;
//    border-left: 0;
//    border-bottom: 5px solid transparent;
//    border-top: 5px solid transparent;
//    border-right: 8px solid $grd-drk-top;
//}

#header .mod_navigation .level_3 li:first-child a:hover::after {
  border-right-color: $grd-lgt-top;
  border-bottom-color: transparent;
}

/* Markierung, wenn ein weiteres Submenü vorhanden ist */
#header .mod_navigation .level_3 > li.submenu > a:link span::after {
  content: '\f105';
  font-family: "Font Awesome 5 Free", serif;
  position: absolute;
  right: 15px;
  display: inline-block;
}

/*     -- Navigation - Vierte Ebene
------------------------------------------------- */

/* Vierte Ebene bei MouseOver einblenden */
#header .mod_navigation .level_3 li:hover .level_4 {
  overflow: visible !important;
  margin-top: -1px;
  left: $wdt-navi-level3;
  width: $wdt-navi-level4;
  height: auto;
  float: none;
  display: block;
  background: $green-drk;
  @include box-shadow-nrm;
  @include transition-fst;
}

#header .mod_navigation .level4 li {
  float: none;
  display: inline-block;
  width: 100%;
  border-right: none;
  border-top: 1px solid rgba(51, 51, 51, .5);
  _line-height: 0; /* IE6 only */
  @include transition-fst;
}

#header .mod_navigation .level_4 > li a,
#header .mod_navigation .level_4 > li strong {
  white-space: normal;
  border-left: 1px solid $clr-brd-main;
  padding: ($pad-nav-main-vrt / 2) $pad-nav-main-hor ($pad-nav-main-vrt / 2) $pad-nav-main-hor;
}

#header .mod_navigation .level_4 > li.active,
#header .mod_navigation .level_4 > li.trail {
  color: $orange-drk;
  width: $wdt-navi-level4;
  @include transition-fst;
}

#header .mod_navigation .level_4 > li:hover > a {
  width: $wdt-navi-level4  - ($pad-nav-main-hor * 2) !important;
  color: $orange-drk;
  @include grd-nav-bg;
  @include transition-fst;
}

/* Dreieckchen nebem erstem Level4 Item */
//#header .mod_navigation .level_4 li:first-child a::after {
//    left: -8px;
//    top: 12px;
//    width: 0;
//    height: 0;
//    border-left: 0;
//    border-bottom: 5px solid transparent;
//    border-top: 5px solid transparent;
//    border-right: 8px solid $grd-drk-top;
//}

#header .mod_navigation .level_4 li:first-child a:hover::after {
  border-right-color: $grd-lgt-top;
  border-bottom-color: transparent;
}
