@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      fonts.scss                                             *
     * created on:    28-12-2022 - 09:15:50 GMT+1                            *
     * last modified: 21-01-2023 - 13:49:09 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../../resources/fonts/dosis-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../../../resources/fonts/dosis-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../resources/fonts/dosis-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../resources/fonts/dosis-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../../resources/fonts/dosis-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../resources/fonts/dosis-v27-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
}
