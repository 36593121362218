@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      navigation_mobile.scss                                 *
     * created on:    21-01-2023 - 12:47:30 GMT+1                            *
     * last modified: 21-01-2023 - 13:48:50 GMT+1                            *
     *                                                                       *
     * (c) 2023 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

.mobile_menu {
  .inner {
    padding: 1rem;

    .mod_navigation,
    .mod_customnav {
      margin-left: 1rem;

      li {
        padding: .4rem;

        &.active {
          color: $orange-drk;
        }

        &.submenu_hide::after,
        &.submenu_show::before {
          content: ' \f105';
          font-family: "Font Awesome 5 Free", serif;
          font-weight: bold;
          padding-left: .5rem;
        }

        &.submenu_show::before {
          padding-right: .5rem;
        }

        a:link,
        a:visited {
          color: white;
          text-decoration: none;
        }

        ul.level_2,
        ul.level_3 {
          margin: 1rem;
        }
      }
    }

    .mod_customnav {
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid $orange-drk;
    }
  }
}
