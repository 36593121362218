@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      main.scss                                              *
     * created on:    27-12-2022 - 12:12:01 GMT+1                            *
     * last modified: 27-12-2022 - 12:12:18 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #

*/

/* ====================================
     Allgemeine Einstellungen
   ==================================== */

html {
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;

  /* http: //paulirish.com/2012/box-sizing-border-box-ftw/
     http: //www.peterkroener.de/schoenes-neues-css-box-sizing/ */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url(/files/layout/gfx/bckgr_cover.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body {
  hyphens: auto;
  color: $clr-txt-std;
  font-family: $fnt-fam-p;
}

.clean {
  clear: both;
  content: ".";
  display: block;
  visibility: hidden;
  height: 0;
}

h6 {
  display: none;
}

.mod_navigation {
  li {
    font-size: 1.2rem;
  }
}

#container {
  #main {
    .inside {
      .mod_article {
        padding: 1rem;

        p,
        li,
        td {
          font-size: 1.1rem;
          line-height: 1.6rem;
        }

        ul {
          list-style-type: disc;

          li {
            margin-left: 1.5rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
