@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      links.scss                                             *
     * created on:    28-12-2022 - 11:57:59 GMT+1                            *
     * last modified: 28-12-2022 - 11:58:04 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* ====================================
     Kapitel
   ==================================== */

/*     -- Abschnitt
------------------------------------------------- */

/* ~~~ Unterabschnitt */

#main,
#footer {
  a:link,
  a:visited {
    color: $clr-txt-lnk;
    text-decoration: none;
    display: inline-block;
    @include transition-nrm;
  }

  a:hover,
  a:active {
    color: $clr-txt-lnk-hov;
    text-decoration: none;
    @include transition-nrm;
  }

  a::after {
    position: relative;
    display: block;
    content: "";
    height: 2px;
    width: 0;
    margin-top: 2px;
    transition: width .5s ease;
    background-color: white;
  }

  a:hover::after,
  a:focus::after {
    width: 100%;
  }

  figure a:hover::after {
    width: 0;
  }
}

/*     -- externe Links
------------------------------------------------- */

a[href^="http:"],
a[href^="https:"],
a[href^="ftp:"] {
  background-image: url('../../../resources/link_extern.png');
  background-repeat: no-repeat;
  background-position: 100% 60%;
  padding-right: 15px;
}

a[href^="http:"]:hover,
a[href^="https:"]:hover,
a[href^="ftp:"]:hover {
  background-image: url('../../../resources/link_extern.png');
  background-repeat: no-repeat;
  padding-right: 15px;
}

.shariff a[href^="http:"],
.shariff a[href^="https:"],
.shariff a[href^="ftp:"],
a[href^="https://stefko.com"],
a[href^="https://contao.org"],
a[href^="http://nordlicht.wip"],
a[href^="https://nordlicht.wip"],
a[href^="http://nordlicht-studio.wip"],
a[href^="https://nordlicht-studio.wip"],
a[href^="http://nordlicht-studio.de"],
a[href^="http://www.nordlicht-studio.de"],
a[href^="https://nordlicht-studio.de"],
a[href^="https://www.nordlicht-studio.de"] {
  background-image: none !important;
  padding-right: 0 !important;
}
