@charset "UTF-8";

/*

	 # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      navigation_back2top.scss                               *
     * created on:    06-01-2022 - 14:39:32 GMT+1                            *
     * last modified: 27-12-2022 - 16:02:42 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #

*/

/* ====================================
     back2top
   ==================================== */

.back2top {
  position: fixed;
  bottom: 50px;
  right: 15px;
  z-index: 5;

  & > a:link {
    color: #fff;
    display: block;
    font-size: 2.625rem;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: $brd-rad-nrm;
    border: 1px solid $clr-brd-main;
    @include grd-nav-bg;
    @include box-shadow-nrm;
    @include transition-nrm;
  }
}

.back2top > a:hover {
  color: $clr-txt-nav-hov;
  @include grd-nav-hvr;
  @include transition-nrm;
}
