@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      mixins.scss                                            *
     * created on:    21-07-2018 - 12:22:16 GMT+0200                         *
     * last modified: 06-01-2022 - 16:21:18 GMT+0200                         *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #

*/

/* ====================================
     Kapitel
   ==================================== */

/*     -- Gradienten
------------------------------------------------- */

/* ~~~ Navigation Hintergrund */
@mixin grd-nav-bg {
  background: $grd-drk-btm;
  //background: linear-gradient($grd-drk-top, $grd-drk-btm);
  background: linear-gradient(#4444447f, #1111117f);
}

/* ~~~ Navigation Hover */
@mixin grd-nav-hvr {
  background: $grd-drk-btm;
  background: linear-gradient($grd-drk-top, $grd-drk-btm);
  //background: linear-gradient(to bottom, #81a9b4 0%, #324c59 100%);
}

//$orange-drk
//$orange-mid

/* ~~~ Tabellen Header */
@mixin grd-tabhead {
  background: $orange-drk;
  background: linear-gradient($orange-mid, $orange-drk);
}

///* ~~~ Aside Hintergrund */
//@mixin grd-asd-bg {
//	background: $grd-drk-btm; /* Old browsers */
//	background: -moz-linear-gradient(left,  $grd-drk-btm 0%, $grd-drk-top 100%); /* FF3.6-15 */
//	background: -webkit-linear-gradient(left,  $grd-drk-btm 0%,$grd-drk-top 100%); /* Chrome10-25,Safari5.1-6 */
//	background: linear-gradient(to right,  $grd-drk-btm 0%,$grd-drk-top 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grd-drk-btm', endColorstr='$grd-drk-top',GradientType=1 ); /* IE6-9 */
//}

/* ~~~ Footer */
@mixin grd-footer {
  background: $grd-drk-btm; /* Old browsers */
  background: -moz-linear-gradient(top, $grd-drk-btm 0%, $grd-drk-top 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $grd-drk-btm 0%, $grd-drk-top 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $grd-drk-btm 0%, $grd-drk-top 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='$grd-drk-btm', endColorstr='$grd-drk-top', GradientType=0); /* IE6-9 */
}

///* ~~~ Gradient BG */
//@mixin grd-bg {
//	background-color: rgb(244,243,240); /* Old browsers */
//	background-color: -moz-linear-gradient(top, rgba(244,243,240,1) 0%, rgba(217,242,207,1) 100%); /* FF3.6-15 */
//	background-color: -webkit-linear-gradient(top, rgba(244,243,240,1) 0%,rgba(217,242,207,1) 100%); /* Chrome10-25,Safari5.1-6 */
//	background-color: linear-gradient(to bottom, rgba(244,243,240,1) 0%,rgba(217,242,207,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f3f0', endColorstr='#d9f2cf',GradientType=0 ); /* IE6-9 */
//	background-attachment: fixed;
//}
//
//@mixin grd-article-bg { /* schwarz-anthrazit */
//	background: linear-gradient($grd-drk-top, $grd-drk-btm);
////	box-shadow: 0 1px 1px $grd-drk-mid;
//}

/*     -- Radien
------------------------------------------------- */

@mixin border-radius($radius) {
  border-radius: $radius;
}

/*     -- Schatten
------------------------------------------------- */

@mixin box-shadow-none {
  box-shadow: none;
}

@mixin box-shadow-nrm {
  box-shadow: $sdw-img-nrm;
}

@mixin box-shadow-drk {
  box-shadow: $sdw-img-drk;
}

/*     -- Transitions
------------------------------------------------- */

@mixin transition-nrm {
  transition: $trs-nrm;
}

@mixin transition-fst {
  transition: $trs-fst;
}

@mixin transition-slw {
  transition: $trs-slw;
}

/* ====================================
     Icons
   ==================================== */

/*     -- Allgemein
------------------------------------------------- */

@mixin ico-address {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f041';
}

@mixin ico-telephone {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f095';
}

@mixin ico-fax {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f1ac';
}

@mixin ico-email {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f0e0';
}

@mixin ico-www {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f0ac';
}

/*     -- Social Media
------------------------------------------------- */

@mixin ico-facebook {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f09a';
}

@mixin ico-twitter {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f099';
}

@mixin ico-googleplus {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f0d5';
}

@mixin ico-xing {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f168';
}

@mixin ico-lnkedin {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f0e1';
}

@mixin ico-instagram {
  font-family: "Font Awesome 5 Free", serif;
  content: '\f16d';
}
