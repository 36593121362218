@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      variables.scss                                         *
     * created on:    27-12-2022 - 12:14:09 GMT+1                            *
     * last modified: 27-12-2022 - 12:14:12 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

$path-to-theme: 				'/files/Rebschule/theme/';

/* ====================================
     Farben
   ==================================== */

$main-clr:						rgba(21, 120, 66, 1);
$clr-main1:                      $main-clr;

/*     -- Gradienten
------------------------------------------------- */

/* ~~ Verlauf 1 */
$grd-drk-top: 					#324c59; // #1d9b56; //#1a8f4f;//#18864a; //rgba(234,243,229,1);	// orangehell		rgb(246, 230, 181); //#f6e6b5
$grd-drk-btm: 					#1c3e4e; //#324c59; //$main-clr; //rgba(21, 120, 66,1);	// orangedunkel 	rgb(237, 145, 24); //#ed9118

/* ~~ Verlauf 2 */
$grd-lgt-top:					$grd-drk-btm;
$grd-lgt-btm:					$grd-drk-top;

$green-drk:						$main-clr;
$green-lgt:						$grd-drk-top;
$orange-drk:					rgba(237, 145, 24, 1); //#ed9118
$orange-mid:					rgba(252, 205, 80, 1);
$orange-lgt:					rgba(236, 244, 140, 1); //#f6e6b5

/*     -- Standardfarben
------------------------------------------------- */

$clr-bg-hdr:					rgb(21, 120, 66);
$clr-bg-bdy:					white;//#efefef;//rgba(234,243,229,1);
$clr-bg-main: 					white;//rgba(250, 250, 250, 0.85);
$clr-bg-ftr:					$grd-lgt-btm;
$clr-pic-cpt: 					rgb(204, 204, 204); //#ccc	/* Hintergrund Bildunterschrift */
$clr-tab-cpt: 					rgb(204, 204, 204); //#ccc	/* Hintergrund Bildunterschrift */
$clr-brd-main: 					$grd-drk-top;
$clr-brd-tab: 					$main-clr;
$clr-main:	 					$grd-lgt-btm;
$clr-bg-nav:					rgba(255, 255, 255, 1); //#fff
$clr-bg-nav-hov:				rgba(255, 255, 255, 1); //#fff

$clr-gray-drk:					rgb(153, 153, 153); //#999;
$clr-gray-mid:					$clr-pic-cpt;
$clr-gray-lgt:					rgb(221, 221, 221); //#ddd;
$clr-gray-lgtr:					rgb(240, 240, 240); //f0f0f0;

/* ====================================
     Schriften
   ==================================== */

/*     -- Schriftfarben
------------------------------------------------- */
$clr-txt-std:					rgb(255, 255, 255); //#333	/* Standard Schriftfarbe */
$clr-txt-bdy:					$clr-txt-std;
$clr-txt-ftr:					$clr-txt-std;
$clr-txt-hdr:					$clr-txt-std;
$clr-txt-lnk:					$orange-drk;
$clr-txt-lnk-hov:				$orange-drk;
$clr-txt-nav:					white;
$clr-txt-nav-hov:				$orange-drk;

/*     -- Schriftenarten
------------------------------------------------- */

/* folgende Schriften sind installiert: Dosis|Open+Sans|Source+Sans+Pro|Ubuntu|Ubuntu+Mono|Playfair+Display */

$fnt-fam-h: 					Dosis, Raleway, Helvetica, Arial, sans-serif;
$fnt-fam-p: 					Dosis, Helvetica, Arial;
$fnt_fam-nav:                   Dosis, helvetica, arial, sans-serif;
$fnt-fam-cde: 					'Ubuntu Mono', monospace;
$fnt-fam-srf: 					'Playfair Display', serif;

/*     -- Schriftgrößen
------------------------------------------------- */

$fnt-size-h1: 					3rem;
$fnt-size-h2: 					2.5rem;
$fnt-size-h3: 					2rem;
$fnt-size-h4: 					1.5rem;
$fnt-size-h5: 					1.25rem;
$fnt-size-p: 					1.2rem;
$fnt-size-li:					1.2rem;
$fnt-size-big: 					1.3rem;
$fnt-size-sml: 					1rem;
$fnt-size-cpt: 					.9rem;
$fnt-size-ftr:					.9rem;
$fnt-size-nav:					20px;

/* ====================================
     Maße
   ==================================== */

/*     -- Höhen
------------------------------------------------- */

$hgt-top-line:					33px;
$hgt-header: 					220px; // in der j_stickyheader.html5 muss der Sprungwert für .sticky angepasst werden
$hgt-header-phone:				160px;
$hgt-navi-main: 				40px;
$sticky:						30px; // Betrag um welchen sich $hgt-navi-main verkleinert bei #header.sticky
$hgt-navi-main-l2:				30px;
$hgt-navi-main-l3:				20px;
$hgt-navi-main-l4:				20px;
$hgt-navi-main-sticky:			0;
$hgt-navi-mobile:				60px;
$hgt-search:					33px;
$hgt-footer:					auto;
$hgt-brdcrmp:					30px;
$hgt-navi-brd-btm:				10px;	// Border unter einem Navifeld bei Active oder Hover

/*     -- Breiten
------------------------------------------------- */

//$wdt-page-std:				960px;		// für das 12er grid mit 960px Breite
$wdt-page-std: 					1200px;		// für das 16er Grid (pixelbasiert) - 1120px breit
$wdt-side-left:					50px;		// Breite der linken Seitenspalte
$wdt-side-right:				300px;		// Breite der rechten Seitenspalte
$wdt-navi-main: 				100%;
$wdt-navi-max: 					$wdt-page-std; 	// Breite der Navi, wenn rechts noch Suchfeld folgt
$wdt-navi-level1:				auto;
$wdt-navi-level2: 				300px;
$wdt-navi-level3:				250px;
$wdt-navi-level4:				250px;
$wdt-search:					35px;

/*     -- Paddings
------------------------------------------------- */

$pad-nav-main-vrt: 				31px;
$pad-nav-main-hor: 				30px;

/*     -- Border Radien
------------------------------------------------- */

$brd-rad-none:					0;
$brd-rad-nrm:					5px;
$brd-rad-sml:					3px;
$brd-rad-big:					10px;

$brd-rad-pic: 					3px;
$brd-rad-box:					3px;
$brd-rad-ico:					$brd-rad-box;
$brd-rad-pgn:					5px;			// Paginierung

/*     -- Schatten
------------------------------------------------- */

$sdw-img-nrm:					0 0 5px rgba(0, 0, 0, .5);
$sdw-img-drk:					0 0 5px rgba(0, 0, 0, .75);
$sdw-img-lgt:					0 0 5px rgba(0, 0, 0, .25);

/*     -- Transitions
------------------------------------------------- */

$trs-nrm: 						all .3s ease;
$trs-slw: 						all .65s ease;
$trs-fst: 						all .15s ease;

/* ====================================
     Icons
   ==================================== */

/*     -- Social Media
------------------------------------------------- */
