@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      rs_slider.scss                                         *
     * created on:    06-03-2018 - 10:29:16 GMT+1                            *
     * last modified: 27-12-2022 - 12:14:40 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* ====================================
     Kapitel
   ==================================== */

/*     -- Abschnitt
------------------------------------------------- */

/* ~~~ Unterabschnitt */

.mod_rocksolid_slider {
  margin: 0 auto;
}
