@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      structure.scss                                         *
     * created on:    27-12-2022 - 12:56:24 GMT+1                            *
     * last modified: 21-01-2023 - 13:48:33 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* ====================================
     Kapitel
   ==================================== */

/*     -- Abschnitt
------------------------------------------------- */

/* ~~~ Unterabschnitt */

#header {
  position: sticky; //--> https://codepen.io/Lobsterlounge/pen/ZxZjXB
  top: -($hgt_header - $hgt-navi-main + $hgt-navi-main-sticky);
  height: $hgt-header;
  width: 100%;
  z-index: 999;
  @include transition-nrm;

  .inside {
    max-width: $wdt-page-std;
    height: $hgt-header;
    margin: 0 auto;
    text-align: center;
    background: rgba(0, 0, 0, .6);

    .logo img {
      height: 160px;
      width: auto;
      padding: 10px;
    }
  }

  .sticky {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .75);
    height: $hgt-header - 30px;
    @include transition-nrm;

    .inside {
      height: $hgt-header - 30px;
    }

    @media (max-width: 880px) {
      display: none;
    }
  }
}

#container {
  #main {
    .inside {
      max-width: $wdt-page-std;
      margin: 0 auto;
      background-color: rgba(0, 0, 0, .6);
    }
  }
}

#footer {
  .inside {
    max-width: $wdt-page-std;
    margin: 0 auto;
    background: $clr-bg-ftr;

    .copyright {
      text-align: center;
    }
  }
}
