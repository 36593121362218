@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      images.scss                                            *
     * created on:    29-12-2022 - 09:15:55 GMT+1                            *
     * last modified: 21-01-2023 - 13:48:55 GMT+1                            *
     *                                                                       *
     * (c) 2023 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

// GLightbox Overlay

.goverlay {
  background: rgba(0, 0, 0, .82);
}

// Bilder Galerien

.ce_gallery {
  ul {
    li {
      list-style-type: none;
      margin: 0 !important;
    }
  }
}

.ce_gallery > ul li.col_first,
.content-gallery > ul li.col_first {
  clear: none;
}
