@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      navigation_footer.scss                                 *
     * created on:    28-12-2022 - 11:44:37 GMT+1                            *
     * last modified: 28-12-2022 - 11:44:40 GMT+1                            *
     *                                                                       *
     * (c) 2022 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

/* ====================================
     Kapitel
   ==================================== */

/*     -- Abschnitt
------------------------------------------------- */

/* ~~~ Unterabschnitt */

#footer {
  .mod_customnav {
    width: 100%;
    text-align: center;

    ul {
      margin: 0 auto;
      width: 100%;

      li {
        display: inline-block;
        padding: 1rem 2rem;
      }
    }
  }
}
