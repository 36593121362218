@charset "UTF-8";

/*
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
     *                                                                       *
     * project:       Nordlicht-Studio                                       *
     * filename:      responsive.scss                                        *
     * created on:    21-01-2023 - 11:54:23 GMT+1                            *
     * last modified: 21-01-2023 - 13:48:41 GMT+1                            *
     *                                                                       *
     * (c) 2023 Stefan '5†3fk0' Kohler - eMail: stefko@lobsterlounge.de      *
     *                                     web: http://www.lobsterlounge.de  *
     *                                                                       *
     # * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * #
*/

.mod_mobile_menu {
  display: none;
}

@media (min-width: 700px) and (max-width: 950px) {
  #header .mod_navigation li {
    font-size: 1rem;
  }

  #header .mod_navigation .level_1 > li a,
  #header .mod_navigation .level_1 > li strong,
  #header.sticky .mod_navigation .level_1 > li a,
  #header.sticky .mod_navigation .level_1 > li strong {
    padding: 10px 1rem 12px 1rem;
  }
}

@media (min-width: 100px) and (max-width: 699px) {
  #header::after {
    content: none;
    bottom: 0;
    min-width: 100%;
    //background: red;
  }

  #header.sticky .mod_navigation::after {
    display: inline-block;
    height: 25px;
    margin-top: -10px;
  }

  #header .inside .logo img {
    height: 120px;
  }

  #header .mod_navigation {
    display: none;
  }

  #header .mod_navigation ul {
    display: none;
  }

  .mod_mobile_menu {
    display: block;
    background: $grd-drk-top;

    .navi-trigger {
      img {
        height: 40px;
        width: auto;
        padding: 5px;
      }
    }
  }
}
