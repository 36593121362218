
$brk-tablet: 800px;

div [class*="grid_wrapper_"] {
  display: grid;
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  //margin-bottom: 30px;
}

// // // Halbe

.grid_wrapper_50-50 {
  grid-template-columns: 1fr 1fr;

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
  }
}

.grid_wrapper_75-25 {
  grid-template-columns: 3fr 1fr;

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
  }
}

.grid_wrapper_25-75 {
  grid-template-columns: 1fr 3fr;

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
  }
}

.grid_wrapper_33-66 {
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "first second";

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "second"
      "first";
  }

  nav {
    grid-area: first;

    @media (max-width: $brk-tablet) {
      text-align: center;
    }
  }
}

.grid_wrapper_66-33 {
  grid-template-columns: 2fr 1fr;

  @media (max-width: $wdt-page-std) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
  }
}

// // // Drittel
.grid_wrapper_33-33-33 {
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: $brk-tablet) {
    grid-template-columns: 1fr;
  }

  section {
    border-right: 1px solid transparent;
    text-align: center;
    padding: 10px;
  }

  section:last-of-type {
    border-right-width: 0;
  }
}

// // // Viertel

.grid_wrapper_25-25-25-25 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid_wrapper_50-50 .grid50:first-child,
.grid_wrapper_75-25 .grid75:first-child {
  //border-right: 1px solid #ccc;
  //padding-right: 20px;
}
